import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, IconProfileSetup } from '../../components';

import css from './ListingPage.module.css';

const SectionServices = props => {
  const {
    services,
    isOwnListing,
    setInitialValues,
    fetchServicesError,
    setFinishModalOpen,
  } = props;

  const servicesError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.servicesError" />
    </h2>
  );

  return (services && Array.isArray(services) && services.length
    ? <div>
      <hr className={css.dividerLine} />
      <div className={css.SectionServices}>
        <h2 className={css.locationLogistics}>
          <FormattedMessage id="ListingPage.servicesHeading" />
        </h2>
        <div>
          {services && Array.isArray(services) && services.length && isOwnListing
            ? <Button
              className={css.addServicesHeading}
              onClick={() => setFinishModalOpen('SERVICE')}
            >
              <FormattedMessage id="ListingPage.addServicesHeading" />
            </Button>
            : null}
        </div>
      </div>
      {fetchServicesError ? servicesError : null}
      <div className={css.accordanceBoxWraper}>
        {services.map(({ customTitle, subCategories, description, docs, uploadImage, uploadVideo }, index) => (<details className={css.accordanceBox} key={customTitle + "-" + index}>
          <summary className={css.accordionTitle}>
            <div className={css.leftBox}>
              {uploadImage
                ? <img alt={uploadImage.original_filename} src={uploadImage.secure_url} />
                : uploadVideo
                  ? <video width="102" height="102" controls>
                    <source alt={uploadVideo.original_filename} src={uploadVideo.secure_url} type="video/mp4" />
                  </video>
                  : docs
                    ? <a target="_blank" href={docs.secure_url}>
                      {docs.original_filename}
                    </a>
                    : null}
              <div className={css.accordionDetail}>
                <h4>{customTitle}</h4>
                {/* {subCategories && <p>View our list of {subCategories.label} to complement your delectable doughnuts.</p>} */}
              </div>
            </div>
          </summary>
          <div className={css.faqBody}>
            <div className={css.accordionList}>
              {isOwnListing
                ? <span onClick={() => [setFinishModalOpen('SERVICE'), setInitialValues({ customTitle, subCategories, description, docs, uploadImage, uploadVideo, index })]}>
                  <IconProfileSetup type="edit" />{' '}
                </span>
                : null}
              {description}
            </div>
          </div>
        </details>))}
      </div>
    </div>
    : isOwnListing
      ? <div>
        <hr className={css.dividerLine} />
        <div className={css.SectionServices}>
          <h2 className={css.locationLogistics}>
            <FormattedMessage id="ListingPage.servicesHeading" />
          </h2>
        </div>
        {fetchServicesError ? servicesError : null}
        {/* <span>
          No Services & Offerings
        </span> */}
        <Button
          className={css.socialUploadButton}
          onClick={() => setFinishModalOpen('SERVICE')}
        >
          <FormattedMessage id="ListingPage.addServicesHeading" />
        </Button>
      </div>
      : null);
};

export default SectionServices;
