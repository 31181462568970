import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, IconProfileSetup, Reviews } from '../../components';

import css from './ListingPage.module.css';

export const faq = [
  {
    key: 'key',
    title: "Our Cancellation Policy",
    description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit."
  },
  {
    key: 'key',
    title: "What To Expect",
    description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit."
  },
  {
    key: 'key',
    title: "Vegan & Peanut/Gluten Free Options",
    description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit."
  },
];

const SectionFAQ = props => {
  const {
    faqs,
    isOwnListing,
    setInitialValues,
    setFinishModalOpen,
  } = props;

  return (faqs && Array.isArray(faqs) && faqs.length
    ? <div>
      <hr className={css.dividerSocial} />
      <div className={css.sectionFaqs}>
        <h2 className={css.locationLogistics}>
          <FormattedMessage id="ListingPage.faqsHeading" />
        </h2>
        <div>
          {faqs && Array.isArray(faqs) && faqs.length && isOwnListing
            ? <Button
              className={css.addFaqsHeading}
              onClick={() => setFinishModalOpen('FAQ')}
            >
              <FormattedMessage id="ListingPage.faqsAddNew" />
            </Button>
            : null}
        </div>
      </div>
      <div className={css.accordanceBoxWraper}>
        {faqs.map(({ question, answer }, index) => (
          <details key={question + '-' + index} className={css.accordanceBox}>
            <summary className={css.accordionTitle}>
              <div className={css.leftBox}>
                <div className={css.accordionDetail}>
                  <h4>{question}</h4>
                </div>
              </div>
            </summary>
            <div className={css.faqBody}>
              {isOwnListing
                ? <span className={css.faqEdit} onClick={() => [setFinishModalOpen('FAQ'), setInitialValues({ question, answer, index })]} >
                  <IconProfileSetup type="edit" />
                </span>
                : null}
              <div className={css.accordionDescription}>
                {answer.trim()}
              </div>
            </div>
          </details>
        ))}
      </div>
    </div>
    : isOwnListing
      ? <div className={css.sectionReviews}>
        <hr className={css.dividerSocial} />
        <h2 className={css.locationLogistics}>
          <FormattedMessage id="ListingPage.faqsHeading" />
        </h2>

        <div>
          <span className={css.locationLogisticsDescription}>
            <FormattedMessage id="ListingPage.faqsSubHeading" />
          </span>
          <Button
            className={css.socialUploadButton}
            onClick={() => setFinishModalOpen('FAQ')}
          >
            <FormattedMessage id="ListingPage.faqsAddNew" />
          </Button>
        </div>
      </div>
      : null);
};

export default SectionFAQ;
