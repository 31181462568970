import React from 'react';

import { NamedLink } from '../../components';

import css from './ListingPage.module.css';

// const getCertificateInfo = (certificateOptions, key) => {
//   return certificateOptions.find(c => c.key === key);
// };

const SectionHeading = props => {
  const {
    reviews,
    publicData,
  } = props;

  const { eventSize, categories = [] } = publicData || {};

  const subCategories = categories && categories.length
    ? categories.map(c => (c.subCategory && c.subCategory.length && c.subCategory)).flat(1)
    : null;
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <div className={css.authorBox}>
          {reviews && Array.isArray(reviews) && reviews.length
            ? <span>
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5798 4.22554L8.68268 3.67806L6.94055 0.264102C6.89297 0.170629 6.81469 0.0949614 6.71799 0.0489671C6.47547 -0.0667604 6.18077 0.0296792 6.05951 0.264102L4.31738 3.67806L0.420236 4.22554C0.312792 4.24038 0.214557 4.28934 0.139346 4.36352C0.0484205 4.45386 -0.00168366 4.5754 4.32016e-05 4.70142C0.00177006 4.82745 0.0551867 4.94766 0.148556 5.03563L2.96819 7.69291L2.30204 11.4452C2.28642 11.5324 2.29641 11.6222 2.33088 11.7043C2.36536 11.7864 2.42293 11.8575 2.49708 11.9095C2.57123 11.9616 2.65898 11.9925 2.75039 11.9988C2.8418 12.0051 2.9332 11.9865 3.01424 11.9452L6.50003 10.1736L9.98582 11.9452C10.081 11.9941 10.1915 12.0104 10.2974 11.9926C10.5645 11.9481 10.7441 11.7033 10.698 11.4452L10.0319 7.69291L12.8515 5.03563C12.9283 4.96293 12.9789 4.86798 12.9943 4.76412C13.0357 4.50447 12.8484 4.26412 12.5798 4.22554Z" fill="#212121" />
              </svg>
              4.5 (4 Reviews)</span>
            : null}
          {eventSize && Array.isArray(eventSize) && eventSize.length
            ? <span>{eventSize[0].label}</span>
            : null}
          {/* <span>Delivery Available</span> */}
          {categories && categories.length
            ? categories.map(c => c.label
              ? <NamedLink
                key={c.label}
                name="AlgoliaSearchPage"
                to={{
                  search: `refinementList[categoriesLabel][0]=${c.label}&page=1&configure[aroundLatLngViaIP]=true&configure[aroundRadius]=all&configure[hitsPerPage]=8`
                }}
              >
                <span>{c.label}</span>
              </NamedLink>
              : null)
            : null}
          {subCategories && subCategories.length
            ? subCategories.map(sc => sc.label
              ? <NamedLink
                key={sc.label}
                name="AlgoliaSearchPage"
                to={{
                  search: `refinementList[subCategoriesLabel][0]=${sc.label}&page=1&configure[aroundLatLngViaIP]=true&configure[aroundRadius]=all&configure[hitsPerPage]=8`
                }}
              >
                <span>{sc.label}</span>
              </NamedLink>
              : null)
            : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
