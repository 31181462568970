import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { IconShare, InlineTextButton } from '../../components';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { richTitle, description, shareModalOpen, currentUser, author, onSendMessage, history } = props;

  const onContactUser = (currentUser, author) => {
    return onSendMessage({
      senderId: currentUser.id.uuid,
      receiverId: author.id.uuid
    })
      .then(() => {
        const channelId = [currentUser.id.uuid, author.id.uuid].sort().join('_')
        typeof window !== 'undefined' && window.sessionStorage.setItem('channelId', channelId);
        return history.push(createResourceLocatorString('ChatPage', routeConfiguration(), {}));
      });
  }

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" values={{ title: richTitle }} />
      </h2>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      <div className={css.descriptionShare}>
        {/* <span className={css.linkShare} onClick={() => onContactUser(currentUser, author)}>
          <InlineTextButton>
            <IconShare type="message" />
            <FormattedMessage id="ListingPage.contactUser" />
          </InlineTextButton>
        </span> */}
        <span className={css.linkShare}>
          <InlineTextButton
            enforcePagePreloadFor="SignupPage"
            onClick={() => shareModalOpen()}
          >
            <IconShare type="share" />
            <FormattedMessage id="ListingPage.shareUser" />
          </InlineTextButton>
        </span>
      </div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
