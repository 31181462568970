import React from 'react';
import Slider from 'react-slick';

import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel, SocialShare, AspectRatioWrapper } from '../../components';
// import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    PageUrl,
    isOwnListing,
    editParams,
    categories,
    subCategories,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const {
    publicData,
  } = listing.attributes || {};
  const { categoriesLabel, subCategoriesLabel } = publicData || {};
  const categoriesImages = hasImages 
    ? []
    : categories && categories.length && categoriesLabel && categoriesLabel.length
      ? [...categories.filter(c => categoriesLabel.includes(c.label)).map(c => c.image), ...subCategories.filter(sc => subCategoriesLabel.includes(sc.label)).map(sc => sc.image)]
      : [];
  // const firstImage = hasImages ? listing.images[0] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  // const actionBar = listing.id ? (
  //   <div onClick={e => e.stopPropagation()}>
  //     <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
  //   </div>
  // ) : null;

  // const viewPhotosButton = hasImages ? (
  //   <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
  //     <FormattedMessage
  //       id="ListingPage.viewImagesButton"
  //       values={{ count: listing.images.length }}
  //     />
  //   </button>
  // ) : null;

  const settings = {
    dots: true,
    infinite: categoriesImages.length < 1 ? false : true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    prevArrow: false,
    nextArrow: false,
    verticalSwiping: true,
    dotsClass: 'slick-dots slick-thumb',
    nextArrow: false,
    prevArrow: false,
    arrows: false,
  };

  return (
    <div className={css.sectionImages}>
      {/* onClick={handleViewPhotosClick} */}
      {/* {actionBar} */}
      {hasImages || (categoriesImages && categoriesImages.length)
        ? <div>
          <Slider {...settings} className={css.swiperSlide}>
            {hasImages
              ? listing.images.map(image => (
                <div key={image}>
                  <AspectRatioWrapper width={10} height={4} className={css.sliderImages}>
                    <ResponsiveImage
                      key={image.id.uuid}
                      className={css.sliderSwiping}
                      alt={image.id.uuid}
                      image={image}
                      variants={[
                        'landscape-crop',
                        'landscape-crop2x',
                        'landscape-crop4x',
                        'landscape-crop6x',
                      ]}
                    />
                  </AspectRatioWrapper>
                </div>
              ))
              : categoriesImages.map(categoriesImage => (
                <div key={categoriesImage}>
                  <AspectRatioWrapper width={10} height={4} className={css.sliderImages}>
                    <ResponsiveImage
                      className={css.sliderSwiping}
                      alt={categoriesImage}
                      url={categoriesImage}
                      variants={[
                        'landscape-crop',
                        'landscape-crop2x',
                        'landscape-crop4x',
                        'landscape-crop6x',
                      ]}
                    />
                  </AspectRatioWrapper>
                </div>
              ))}
          </Slider>
          <SocialShare
            isPlain={true}
            publicData={publicData}
            className={css.socialShare}
            onClose={() => console.log('**** SectionImages **** => ')}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        </div>
        : <div className={css.noImage}>No Image</div>}


      {/* {viewPhotosButton} */}
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionImages;
