import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import { Accordion } from '../../components';

/////////////
// Weekday //
/////////////
const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);
  const daysEntries = getEntries(availabilityPlan, dayOfWeek);
  return daysEntries && daysEntries.length ? (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      role="button"
    >
      {/* <div className={css.dayOfWeek}>
      </div> */}
      <div className={css.entries}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
        {availabilityPlan && hasEntry
          ? daysEntries.map(e => e.startTime && e.endTime && (
            <span className={css.entry} key={`${e.dayOfWeek}${moment(e.startTime, "HH:mm").format("hh:mm a")}`}>
              {`${moment(e.startTime, "HH:mm").format("hh:mm a")} - ${e.endTime === '12:00 am' ? '12:00 pm' : moment(e.endTime, "HH:mm").format("hh:mm a")}`}
            </span>))
          : null}
      </div>
    </div>
  ) : null;
};

const SectionFeaturesMaybe = props => {
  const { availabilityPlan, publicData, intl } = props;
  if (!publicData) {
    return null;
  }

  return (
    <div className={css.operationFeatures}>
      <Accordion title={intl.formatMessage({ id: "ListingPage.featuresTitle" })}>
        <div className={css.weekNumber}>
          {availabilityPlan && availabilityPlan.entries && availabilityPlan.entries.length
            ? WEEKDAYS.map(w => (
              <Weekday
                dayOfWeek={w}
                key={w}
                availabilityPlan={availabilityPlan}
              />
            ))
            : null}
        </div>
      </Accordion>
    </div>
  );
};

export default SectionFeaturesMaybe;
